import React from "react";
import "./footer.styles.scss";
import {Logo} from "../UI/buttons/logo/_logo";
import {GooglePlayButton} from "../UI/buttons/google_play/_google_play";
import {DownloadApkButton} from "../UI/buttons/download_apk/_download_apk";
import {NavigationFooter} from "./_navigation_list";
import {Feedback} from "../feedback";
import {AppGalleryButton} from "../UI/buttons/app_gallery/_app_gallery";
import * as ROUTE_PATH from "src/routes/const_routes";
import {BrowserView} from "react-device-detect";
import {useTranslation} from "react-i18next";
import {PaygineButton} from "../UI/buttons/paygine/_paygine";

export const Footer: React.FC = () => {
    const {t} = useTranslation();

    return (
        <footer>
            <div className="container">
                <div className="row footer__header">
                    <div className="column-6">
                        <div className="row logo-container">
                            <Logo path={ROUTE_PATH.HOME_PATH}/>
                        </div>
                        <BrowserView>
                            <NavigationFooter/>
                        </BrowserView>
                    </div>
                    <div className="column-4">
                        <Feedback/>
                    </div>
                </div>
            </div>
            <div className="footer_outside_container">
                <div className="container">
                    <div className="row footer__political">

                        <div className="footer__political__copyright">
                            {t('ourCompany')}
                        </div>

                        <div className="download-buttons-block">
                            <div className="row">
                                <GooglePlayButton/>
                            </div>
                            <div className="row">
                                <DownloadApkButton/>
                            </div>
                        </div>
                        <div className="footer__political__private">
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};
